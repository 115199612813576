@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700&display=swap');

/* COLORS */
:root {
  --white-color: #FFF;
  --dark-blue-color: #102866;
  --light-blue-color: #29ABF6;
  --bg-blue-color: #108FE5;
  --dark-gray-color: #F4F5F7;
  --gray-border-color: #eee;
  --main-text-color: #383A3D;

}


body {
  font-family: 'Poppins', sans-serif;
  color: var(--main-text-color);
  font-size: 18px;
  background-color: var(--dark-gray-color);
}

a {
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  color: var(--main-text-color);
  font-size: 18px;
}

.nav.nav-tabs {
  border-bottom: none;
}

.tab-content {
  margin-bottom: 40px;
  margin-top: -10px;
  color: var(--white-color);
}

.tab-content .tab-pane {
  background-color: #151515;
  border-radius: 10px;
}

.nav.nav-tabs .nav-item .nav-link {
  color: var(--main-text-color);
  padding: 16px 40px;
  border: none;
}

.nav.nav-tabs .nav-item .nav-link:hover {
  border: none;
}

.nav.nav-tabs .nav-item .nav-link.active {
  color: var(--white-color);
  border: none;
  background-color: #151515;
  padding: 16px 40px;
}

.hljs {
  background-color: #151515;
  border-radius: 10px;
}

.hljs-ln-n {
  margin-right: 15px;
}

.main-title {
  font-weight: 600;
  font-size: 32px;
}

.main-description {
  margin-bottom: 35px;
}

.fixed {
  overflow: hidden;
}


@media screen and (max-width: 1120px) {
  body {
    font-size: 16px;
  }

  a {
    font-size: 16px;
  }

  .main-title {
    font-size: 26px;
  }

}

@media screen and (max-width: 640px) {
  .nav.nav-tabs .nav-item .nav-link{
    padding: 16px 20px;
  }
  .nav.nav-tabs .nav-item .nav-link.active {
    padding: 16px 20px;
  }
}

@media screen and (max-width: 440px) {
.nav{
  flex-direction: column;
  text-align: center;
}
}
